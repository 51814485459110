@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.root {
  display: flex;
  flex-direction: column;
  gap: 1px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background: $yds-color-gray-80;
}
