@use 'styles/spacing';
@use 'styles/typography';
@use 'styles/breakpoints';

.label {
  align-items: center;
  display: flex;

  @include typography.default-s;
}

.text {
  margin-left: spacing.$spacing-8;
}
