@use 'styles/breakpoints.scss';
@use 'styles/spacing.scss';

.root {
  display: flex;
  column-gap: spacing.$spacing-40;
  row-gap: spacing.$spacing-20;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: spacing.$spacing-20;
  margin-top: spacing.$spacing-20;

  @include breakpoints.media(tablet) {
    justify-content: normal;
  }
}

.pickerWrapper {
  flex-basis: 20rem;
  justify-self: center;
}

.toggleWrapper {
  display: flex;
}
