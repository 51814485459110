@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/spacing';
@use 'styles/typography';

.root {
  display: flex;

  @include typography.default-s;
}

.link {
  align-items: center;
  background-color: $yds-color-gray-60;
  border-radius: 50vh;
  color: $yds-color-white;
  display: flex;
  flex-shrink: 0;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;
}

.linkIcon {
  height: 1.25rem;
  width: 1.25rem;
}

.inputWrapper {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  padding-left: spacing.$spacing-20;
  padding-right: spacing.$spacing-20;
  text-align: center;
  justify-content: center;
}

.button {
  cursor: pointer;
  display: block;
  appearance: none;
  background: none;
  border: none;
  color: currentColor;
  font: inherit;
  padding: 0;
  margin: 0;

  &[hidden] {
    display: none;
  }
}

.input {
  align-self: center;
  padding: spacing.$spacing-6;
  font: inherit;

  &:invalid {
    border: 2px solid $yds-color-feedback-error;
  }
}
