@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use 'styles/spacing.scss';
@use 'styles/typography';

.channelRoot {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
}

.channelProgramsRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $yds-color-gray-80;
  min-height: 300px;
  width: 100%;
  height: 100%;
}

.message {
  @include typography.default-s-italic;
  padding: spacing.$spacing-10 spacing.$spacing-24;
}

.showMoreButtonContainer {
  margin: 0.875rem auto;
}
