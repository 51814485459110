@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-light' as light;
@use 'styles/spacing.scss';
@use 'styles/typography';

.programRoot {
  display: flex;
  flex-direction: column;
  gap: spacing.$spacing-6;
  padding: spacing.$spacing-6 spacing.$spacing-10;
}

.programActive {
  background-color: $yds-color-white;
  transition: background-color 0.2s;
}

.progressRoot {
  background-color: $yds-color-black;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  width: 100%;
  height: 4px;

  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);
}

.progressIndicator {
  background-color: $yds-color-red-60;
  height: 100%;
  min-width: 5%;
  position: absolute;
  transition: width 660ms cubic-bezier(0.65, 0, 0.35, 1);
}

.programInfo {
  @include typography.default-s;

  &[data-state='open'] {
    color: $yds-color-gray-95;
  }
}

.programHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  gap: 5px;
  width: 100%;
  background-color: transparent;
  color: $yds-color-white;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font-family: inherit;
}

.programHeaderActive .programTitle {
  @include typography.default-s-bold;
}
.programHeaderActive {
  @include typography.default-s-bold;
  color: $yds-color-gray-95;

  &:focus-visible {
    outline-color: light.$yds-color-border-primary-focus;
  }
}

.programTitle {
  @include typography.default-s;
  display: inline;
  margin: 0;
  margin-right: spacing.$spacing-8;

  &:last-child {
    margin-right: 0;
  }
}

.programTitleRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%; // fix for Safari
  gap: 5px;
}

.programTime {
  @include typography.default-s;
}

.playIcon {
  margin-top: 4px;
  flex: 0 0 13px;
  fill: $yds-color-gray-50;
}

.activePlayIcon {
  fill: $yds-color-turquoise-60;
}

.controlLink {
  font: inherit;
  @include typography.default-s-bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: spacing.$spacing-8;
  color: $yds-color-gray-95;
  text-decoration: none;
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;

  .controlLinkPlayButton circle {
    fill: $yds-color-turquoise-40;
  }
  .controlLinkPlayButton path:last-child {
    fill: $yds-color-turquoise-40;
  }

  &:hover {
    .controlLinkPlayButton circle {
      fill: $yds-color-brand;
    }
    .controlLinkPlayButton path:last-child {
      fill: $yds-color-brand;
    }
  }

  &.controlDisabled {
    cursor: default;
    color: $yds-color-gray-95;

    .controlLinkPlayButton circle {
      fill: transparent;
    }
    .controlLinkPlayButton path {
      fill: $yds-color-gray-95;
    }
    .controlLinkPlayButton path:last-child {
      fill: $yds-color-gray-95;
    }
  }

  &:focus-visible {
    outline-color: light.$yds-color-border-primary-focus;
  }
}

.controlLinkPlayButton {
  width: 24px;
  height: 24px;

  circle {
    fill: transparent;
  }

  path {
    fill: $yds-color-white;
  }
}

.marker {
  border-radius: 2px;
  display: inline-block;
  font-size: 0.75em;
  font-weight: bold;
  line-height: 1;
  padding: 0.1375em 0.375em;
  text-transform: uppercase;
  vertical-align: middle;
  font-style: normal;
  color: $yds-color-white;
  background: $yds-color-gray-95;
}

.controlsContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: spacing.$spacing-16;
  margin-top: spacing.$spacing-12;
  margin-bottom: spacing.$spacing-6;
}

.noRights {
  @include typography.default-s-bold;
  color: $yds-color-gray-95;
  display: block;
  font-style: normal;
}

.infoMarker {
  @include typography.default-s;
}
