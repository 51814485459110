@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.wrapper {
  position: relative;
  display: inline-block;
}

.button {
  appearance: none;
  color: transparent;
  background-color: $yds-color-gray-60;
  border: none;
  cursor: pointer;
  overflow: hidden;
  width: 2rem;
  height: 2rem;
  border-radius: $yds-border-radius-full;

  option {
    color: $yds-color-white;
    background-color: $yds-color-gray-60;
    margin: 0;
    padding: 0;
    border: none;
  }
}

.chevronDownIcon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  color: $yds-color-white;
}
