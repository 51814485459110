@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.channelHeaderRoot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 57px;
  background: $yds-color-gray-80;
  padding: 10px;
}

.channelHeaderH2 {
  margin-top: 0;
  margin-bottom: 0;
}
